type Props = React.DetailedHTMLProps<
  React.LabelHTMLAttributes<HTMLLabelElement>,
  HTMLLabelElement
> & {
  children: React.ReactNode
}

const className = 'block text-sm font-medium text-gray-700'

const Label = (props: Props) => <label className={className} {...props} />

export default Label
