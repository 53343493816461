import { Channel as PhoenixChannel } from 'phoenix'

import Session from './Session'
import Channel from './Channel'
import SignUpErrors from './SignUpErrors'
import AddSpaceErrors from './AddSpaceErrors'
import Space from './Space'
import User from './User'

import {
  Credentials,
  SessionPayload,
  AddSpaceErrorsPayload,
  SpacePayload,
  UserPayload,
  InviteForm,
  SignUpErrorsPayload,
} from '../types'

const onSignedIn = (channel: PhoenixChannel, callback: (token: string) => void) =>
  Channel.on<{ token: string }>(channel, 'signed_in', ({ token }) => {
    callback(token)
  })

const onSignedOut = (channel: PhoenixChannel, callback: (token: string) => void) =>
  Channel.on<{ token: string }>(channel, 'signed_out', ({ token }) => {
    callback(token)
  })

const onSpaceAdded = (channel: PhoenixChannel, callback: () => void) =>
  Channel.on(channel, 'space_added', callback)

const { off } = Channel

const fetchToken = (channel: PhoenixChannel) => Channel.push<string>(channel, 'fetch_token')

const fetchSession = (channel: PhoenixChannel) =>
  Channel.push<SessionPayload>(channel, 'fetch_session').then((result) => {
    if (result.status === 'ok') return { ...result, data: Session.fromPayload(result.data) }

    return result
  })

const signIn = (channel: PhoenixChannel, credentials: Credentials) =>
  Channel.push(channel, 'sign_in', credentials)

const signOut = (channel: PhoenixChannel) => Channel.push(channel, 'sign_out')

const signUp = (channel: PhoenixChannel, credentials: Credentials) =>
  Channel.push<unknown, SignUpErrorsPayload>(channel, 'sign_up', credentials).then((result) => {
    if (result.status === 'error') return { ...result, data: SignUpErrors.fromPayload(result.data) }
    return result
  })

const fetchSpaces = (channel: PhoenixChannel, orgId: string) =>
  Channel.push<SpacePayload[]>(channel, 'fetch_spaces', { org_id: orgId }).then((result) => {
    if (result.status === 'ok') return { ...result, data: result.data.map(Space.fromPayload) }
    return result
  })

const addSpace = (channel: PhoenixChannel, orgId: string, attrs: { name: string }) =>
  Channel.push<unknown, AddSpaceErrorsPayload>(channel, 'add_space', {
    org_id: orgId,
    attrs,
  }).then((result) => {
    if (result.status === 'error')
      return { ...result, data: AddSpaceErrors.fromPayload(result.data) }
    return result
  })

const fetchUsers = (channel: PhoenixChannel, orgId: string) =>
  Channel.push<UserPayload[]>(channel, 'fetch_users', { org_id: orgId }).then((result) => {
    if (result.status === 'ok') return { ...result, data: result.data.map(User.fromPayload) }
    return result
  })

const inviteUser = (channel: PhoenixChannel, form: InviteForm) =>
  Channel.push(channel, 'invite_user', { org_id: form.orgId, email: form.email })

export default {
  onSignedIn,
  onSignedOut,
  onSpaceAdded,
  off,
  fetchToken,
  fetchSession,
  signIn,
  signOut,
  signUp,
  addSpace,
  fetchSpaces,
  fetchUsers,
  inviteUser,
}
