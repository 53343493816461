import React from 'react'
import { Socket as PhoenixSocket } from 'phoenix'

import Socket from '../modules/Socket'

const { console } = window

const buildSocket = (url: string, token: string | null) => {
  const socket = new PhoenixSocket(url, { params: { token } })

  socket.onOpen(() => console.log('socket opened', socket))
  socket.onError((error) => console.log('socket errored', socket, error))
  socket.onClose(() => console.log('socket closed', socket))

  return socket
}

const useSocket = (url: string, token: string | null) => {
  const socket = React.useMemo(() => buildSocket(url, token), [url, token])

  React.useEffect(() => {
    Socket.connect(socket) // must be in useEffect for some reason
  }, [socket])

  return socket
}

export default useSocket
