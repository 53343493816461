import { User, UserPayload, OrgMembership, OrgMembershipPayload } from '../types'

import Org from './Org'

const toOrgMembership = (payload: OrgMembershipPayload) =>
  ({
    id: payload.id,
    org: Org.fromPayload(payload.org),
    role: payload.role,
  } as OrgMembership)

const fromPayload = (payload: UserPayload) =>
  ({
    id: payload.id,
    orgMemberships: payload.org_memberships.map(toOrgMembership),
    email: payload.email,
  } as User)

export default { fromPayload }
