import React from 'react'

const tokenKey = 'token-v1'

const useToken = () => {
  const [token, setToken] = React.useState(localStorage.getItem(tokenKey))

  const updateToken = (newToken: string) => {
    setToken(newToken)
    localStorage.setItem(tokenKey, newToken)
  }

  return [token, updateToken] as const
}

export default useToken
