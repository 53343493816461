import { Socket as PhoenixSocket } from 'phoenix'

const { console } = window

const connect = (socket: PhoenixSocket) => {
  socket.connect()
  console.log('socket connected', socket)
}

const channel = (socket: PhoenixSocket, topic: string) => {
  const chan = socket.channel(topic)

  chan.join().receive('ok', (data) => console.log('channel joined', chan, data))

  chan.onError((error) => console.log('channel errored', chan, error))
  chan.onClose(() => console.log('channel closed', chan))

  return chan
}

export default { connect, channel }
