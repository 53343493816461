import React from 'react'
import { Socket as PhoenixSocket } from 'phoenix'
import { Outlet, useParams } from 'react-router-dom'
import { FolderIcon, UsersIcon } from '@heroicons/react/outline'

import OrgChannelContext from '../contexts/OrgChannelContext'
import useAuthenticatedSession from '../hooks/useAuthenticatedSession'
import useSessionChannel from '../hooks/useSessionChannel'
import Socket from '../modules/Socket'
import App from '../modules/App'

import Layout from './Layout'

const AuthenticatedApp = ({ socket }: { socket: PhoenixSocket }) => {
  const sessionChannel = useSessionChannel()
  const session = useAuthenticatedSession()
  const params = useParams()

  const userOrgId = session.user.orgMemberships[0].org.id

  const orgChannel = React.useMemo(() => {
    if (params.orgId) return Socket.channel(socket, `orgs:${params.orgId}`)
    return undefined
  }, [socket, params.orgId])

  const nav = [
    { name: 'Spaces', href: `/${userOrgId}/spaces`, icon: FolderIcon, current: false },
    // { name: 'Users', href: `/${userOrgId}/users`, icon: UsersIcon, current: false },
  ]

  return (
    <Layout navigation={nav}>
      <div>
        {session.user.email} |{' '}
        <button type="button" onClick={() => App.signOut(sessionChannel)}>
          Sign out
        </button>
      </div>

      <OrgChannelContext.Provider value={orgChannel}>
        <Outlet />
      </OrgChannelContext.Provider>
    </Layout>
  )
}

export default AuthenticatedApp
