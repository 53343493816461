import React from 'react'

import App from '../modules/App'

import { OnId } from '../types'

const useOn = (on: () => OnId) =>
  React.useEffect(() => {
    const onId = on()

    return () => App.off(onId)
  }, [])

export default useOn
