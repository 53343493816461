import { SignUpErrors, SignUpError, SignUpErrorsPayload } from '../types'

const toMsg = (error: SignUpError) =>
  ({
    email_required: 'Email is required.',
    email_exists: 'Email is invalid or already taken.',
    email_invalid: 'Email is not valid.',
    password_required: 'Password is required.',
    password_too_short: 'Password is too short.',
  }[error])

const fromPayload = (payload: SignUpErrorsPayload): SignUpErrors => {
  const email = payload.filter((e) => e.startsWith('email_')).map(toMsg)
  const password = payload.filter((e) => e.startsWith('password_')).map(toMsg)

  return { email, password }
}

export default { fromPayload }
