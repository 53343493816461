type Props = React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement> & {
  submit: () => void
}

const Form = ({ children, submit, ...props }: Props) => {
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    submit()
  }

  return (
    <form onSubmit={onSubmit} {...props}>
      {children}
    </form>
  )
}

export default Form
