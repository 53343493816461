import React from 'react'

import useSessionChannel from '../../hooks/useSessionChannel'
import App from '../../modules/App'

import Form from '../Form'
import TextInput from '../TextInput'
import Label from '../Label'
import Button from '../Button'

import { SignInForm } from '../../types'

const SignIn = () => {
  const sessionChannel = useSessionChannel()
  const [form, setForm] = React.useState<SignInForm>({ email: '', password: '' })
  const [error, setError] = React.useState(false)

  const submit = () => {
    setError(false)
    App.signIn(sessionChannel, form).then((result) => {
      if (result.status === 'error') setError(true)
    })
  }

  return (
    <div className="space-y-4">
      <h2 className="text-xl">Sign In</h2>
      {error && <div className="text-red-600">Incorrect email or password.</div>}
      <Form submit={submit} className="w-80 space-y-4">
        <div className="space-y-1">
          <Label htmlFor="email">Email</Label>
          <TextInput
            type="text"
            autoComplete="email"
            value={form.email}
            setValue={(email) => setForm({ ...form, email })}
            id="email"
          />
        </div>
        <div className="space-y-1">
          <Label htmlFor="password">Password</Label>
          <TextInput
            type="password"
            autoComplete="current-password"
            value={form.password}
            setValue={(password) => setForm({ ...form, password })}
            id="password"
          />
        </div>
        <Button type="submit">Sign in</Button>
      </Form>
    </div>
  )
}

export default SignIn
