import React from 'react'

import useOrgChannel from '../hooks/useOrgChannel'
import useAuthenticatedSession from '../hooks/useAuthenticatedSession'

import App from '../modules/App'

import Form from './Form'
import Label from './Label'
import TextInput from './TextInput'
import Button from './Button'

import { AddSpaceErrors } from '../types'

const AddSpaceForm = () => {
  const orgChannel = useOrgChannel()
  const session = useAuthenticatedSession()
  const [form, setForm] = React.useState<{ name: string }>({ name: '' })

  const [errors, setErrors] = React.useState<AddSpaceErrors | null>(null)

  const submit = () => {
    setErrors(null)
    App.addSpace(orgChannel, session.user.orgMemberships[0].org.id, form).then((result) => {
      if (result.status === 'error') setErrors(result.data)
      if (result.status === 'ok') setForm({ ...form, name: '' })
    })
  }

  return (
    <Form submit={submit} className="space-y-2 w-80">
      <div className="text-red-600 text-sm">{errors?.name}</div>
      <div className="flex items-center gap-2">
        <Label htmlFor="name">Name</Label>
        <TextInput
          type="text"
          value={form.name}
          setValue={(name) => setForm({ ...form, name })}
          id="name"
        />
        <Button type="submit">Add</Button>
      </div>
    </Form>
  )
}

export default AddSpaceForm
