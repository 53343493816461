import React from 'react'

import SessionChannelContext from '../contexts/SessionChannelContext'

const useSessionChannel = () => {
  const sessionChannel = React.useContext(SessionChannelContext)
  if (sessionChannel === undefined) throw new Error('must be used within provider')
  return sessionChannel
}

export default useSessionChannel
