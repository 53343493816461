import { AuthenticatedSession, UnauthenticatedSession, SessionPayload } from '../types'

import User from './User'

const fromPayload = (payload: SessionPayload) => {
  if (payload.user) {
    return { ...payload, user: User.fromPayload(payload.user) } as AuthenticatedSession
  }
  return payload as UnauthenticatedSession
}

export default { fromPayload }
