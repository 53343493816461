import React from 'react'
import { Channel } from 'phoenix'

import App from '../modules/App'

import { Session } from '../types'

const useLocalSession = (token: string | null, channel: Channel) => {
  const [session, setSession] = React.useState<Session>()

  React.useEffect(() => {
    if (token) {
      App.fetchSession(channel).then((result) => {
        if (result.status === 'ok') setSession(result.data)
      })
    }
  }, [token])

  return session
}

export default useLocalSession
