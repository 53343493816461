import React from 'react'
import { Socket as PhoenixSocket } from 'phoenix'
import { Routes, Route, Navigate } from 'react-router-dom'

import SessionChannelContext from '../contexts/SessionChannelContext'
import useOn from '../hooks/useOn'
import Socket from '../modules/Socket'
import App from '../modules/App'

import AuthenticatedApp from './AuthenticatedApp'
import UnauthenticatedApp from './UnauthenticatedApp'
import Home from './pages/Home'
import SignIn from './pages/SignIn'
import SignUp from './pages/SignUp'
import Spaces from './pages/Spaces'
import Users from './pages/Users'
import NotFound from './pages/NotFound'

import { Session } from '../types'

const SessionApp = ({
  session,
  socket,
  updateToken,
}: {
  session: Session
  socket: PhoenixSocket
  updateToken: (newToken: string) => void
}) => {
  const sessionChannel = React.useMemo(
    () => Socket.channel(socket, `sessions:${session.id}`),
    [socket, session.id]
  )

  useOn(() => App.onSignedIn(sessionChannel, updateToken))
  useOn(() => App.onSignedOut(sessionChannel, updateToken))

  return (
    <SessionChannelContext.Provider value={sessionChannel}>
      <Routes>
        <Route
          path="/"
          element={session.user ? <AuthenticatedApp socket={socket} /> : <UnauthenticatedApp />}
        >
          <Route path="" element={<Home />} />

          <Route path="sign-in" element={session.user ? <Navigate to="/" /> : <SignIn />} />
          <Route path="sign-up" element={session.user ? <Navigate to="/" /> : <SignUp />} />

          <Route path=":orgId">
            <Route path="spaces" element={session.user ? <Spaces /> : <Navigate to="/sign-in" />} />
            {/* <Route path="users" element={session.user ? <Users /> : <Navigate to="/sign-in" />} /> */}
          </Route>

          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </SessionChannelContext.Provider>
  )
}

export default SessionApp
