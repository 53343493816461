import Rollbar from 'rollbar'
import { Provider as RollbarProvider } from '@rollbar/react'

const config: Rollbar.Configuration = {
  accessToken: import.meta.env.VITE_ROLLBAR_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: import.meta.env.VITE_ROLLBAR_ENABLED === 'true',
  payload: {
    environment: import.meta.env.VITE_ROLLBAR_ENV,
    client: {
      javascript: {
        code_version: '1.0.0',
        source_map_enabled: true,
      },
    },
    server: {
      branch: 'main',
    },
  },
}

const ErrorTracker = ({ children }: { children: React.ReactNode }) => (
  <RollbarProvider config={config}>{children}</RollbarProvider>
)

export default ErrorTracker
