import { Channel as PhoenixChannel } from 'phoenix'

import { OkResult, ErrorResult, TimeoutResult, OnId } from '../types'

const { console } = window

const on = <T = undefined>(
  channel: PhoenixChannel,
  event: string,
  callback: (payload: T) => void
) => {
  console.log(`on: ${event}`)

  const ref = channel.on(event, (payload: T) => {
    console.log(event, payload)
    callback(payload)
  })

  return { channel, event, ref } as OnId
}

const offOne = (onId: OnId) => onId.channel.off(onId.event, onId.ref)

const off = (onIds: OnId | OnId[]) => (Array.isArray(onIds) ? onIds.forEach(offOne) : offOne(onIds))

const push = <OkData = unknown, ErrorData = unknown, TimeoutData = unknown>(
  channel: PhoenixChannel,
  event: string,
  // eslint-disable-next-line @typescript-eslint/ban-types
  payload?: object
) =>
  new Promise<OkResult<OkData> | ErrorResult<ErrorData> | TimeoutResult<TimeoutData>>((resolve) => {
    console.log('push', event, payload || {})

    channel
      .push(event, payload || {})
      .receive('ok', (data) => resolve({ status: 'ok', data }))
      .receive('error', (data) => resolve({ status: 'error', data }))
      .receive('timeout', (data) => resolve({ status: 'timeout', data }))
  })

export default { on, off, push }
