import React from 'react'
import { useParams } from 'react-router-dom'

import useOrgChannel from '../../hooks/useOrgChannel'
import useOn from '../../hooks/useOn'
import App from '../../modules/App'

import AddSpaceForm from '../AddSpaceForm'

import { Space } from '../../types'

const Spaces = () => {
  const params = useParams() as { orgId: string }
  const orgChannel = useOrgChannel()
  const [spaces, setSpaces] = React.useState<Space[] | undefined>(undefined)

  const loadSpaces = () => {
    App.fetchSpaces(orgChannel, params.orgId).then((result) => {
      if (result.status === 'ok') setSpaces(result.data)
    })
  }

  useOn(() => App.onSpaceAdded(orgChannel, loadSpaces))

  React.useEffect(() => {
    loadSpaces()
  }, [])

  if (spaces === undefined) return null

  return (
    <div className="space-y-4">
      <AddSpaceForm />

      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Name
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {spaces.map((space) => (
                  <tr key={space.id}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      {space.name}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Spaces
