import { Outlet } from 'react-router-dom'
import { UsersIcon } from '@heroicons/react/outline'

import Layout from './Layout'

const UnauthenticatedApp = () => {
  const nav = [
    { name: 'Sign in', href: '/sign-in', icon: UsersIcon, current: false },
    { name: 'Sign up free', href: '/sign-up', icon: UsersIcon, current: false },
  ]

  return (
    <Layout navigation={nav}>
      <Outlet />
    </Layout>
  )
}

export default UnauthenticatedApp
