type Props = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  setValue: (value: string) => void
}

const className =
  'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'

const TextInput = ({ setValue, ...props }: Props) => {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => setValue(event.target.value)

  return <input {...props} onChange={onChange} className={className} />
}

export default TextInput
