import React from 'react'

import SessionContext from '../contexts/SessionContext'

const useAuthenticatedSession = () => {
  const session = React.useContext(SessionContext)

  if (session === undefined) throw new Error('useAuthenticatedSession must be used within provider')

  if (!session.user)
    throw new Error('useAuthenticatedSession must be used within authenticated components')

  return session
}

export default useAuthenticatedSession
