import React from 'react'
import { BrowserRouter } from 'react-router-dom'

import SessionContext from '../contexts/SessionContext'
import useToken from '../hooks/useToken'
import useSocket from '../hooks/useSocket'
import useLocalSession from '../hooks/useLocalSession'
import Socket from '../modules/Socket'
import App from '../modules/App'
import ErrorTrackerProvider from '../providers/ErrorTracker'

import SessionApp from './SessionApp'

const AppC = () => {
  const [token, updateToken] = useToken()
  const socket = useSocket(import.meta.env.VITE_WEBSOCKET_URL, token)
  const mainChannel = React.useMemo(() => Socket.channel(socket, 'main'), [socket])
  const session = useLocalSession(token, mainChannel)

  React.useEffect(() => {
    if (!token) {
      App.fetchToken(mainChannel).then((result) => {
        if (result.status === 'ok') updateToken(result.data)
      })
    }
  }, [])

  if (!session) return null

  return (
    <ErrorTrackerProvider>
      <BrowserRouter>
        <SessionContext.Provider value={session}>
          <SessionApp session={session} socket={socket} updateToken={updateToken} />
        </SessionContext.Provider>
      </BrowserRouter>
    </ErrorTrackerProvider>
  )
}

export default AppC
