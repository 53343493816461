import React from 'react'

import OrgChannelContext from '../contexts/OrgChannelContext'

const useOrgChannel = () => {
  const orgChannel = React.useContext(OrgChannelContext)
  if (orgChannel === undefined) throw new Error('must be used within provider')
  return orgChannel
}

export default useOrgChannel
