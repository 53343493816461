import { AddSpaceErrors, AddSpaceErrorsPayload } from '../types'

const toErrorMsg = (errorKey: string) => {
  if (errorKey === 'required') return 'is required.'
  if (errorKey === 'exists') return 'already exists.'
  return errorKey
}

const fromPayload = (payload: AddSpaceErrorsPayload) =>
  ({
    name: payload.name.map(toErrorMsg),
  } as AddSpaceErrors)

export default { fromPayload }
